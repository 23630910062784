import React from 'react';
import URI from 'urijs';

import shopDict from './shopDict.json';

const App = () => {
  const [linkUrl, setLinkUrl] = React.useState(undefined);
  React.useEffect(() => {
    const uri = new URI(window.location);
    const params = uri.query(true);
    const shopId = params.shop_id;
    const shop = shopDict[shopId];
    if (shop) {
      setLinkUrl(shop.linkUrl);
    }
  }, [])

  const handleButton = () => {
    window.open(linkUrl, '_blank');
  };

  return (
    <div className="App">
      <img src="logo.png" className="logo" alt="logo" />
      <p>
        この度は、アンケートにご協力いただき、<br/>
        誠にありがとうございました。
      </p>
      <p>
        今後とも、「カラダファミリーブランド」を<br/>
        ご愛顧いただきますよう、よろしくお願いします。
      </p>
      {linkUrl && (
        <>
          <p>
            <strong>
              よろしければ<br/>
              あと1分だけご協力ください！
            </strong>
          </p>
          <p>
            Googleの口コミ投稿ページにある<br/>
            当店を星の数で評価をお願いします。
          </p>
          <button className="button" onClick={handleButton}>
            星をつける
          </button>
        </>
      )}
    </div>
  );
}

export default App;
